// start with unified webapp config
export const preprEnvironmentIds = {
  bb: '46d45ec7-bd56-4f56-82be-f2f7b5505887',
  bijbelsenamen: 'a8a439b2-c424-4c10-99c2-b674af18c173',
  dit: 'da5bff01-1d38-4f72-b68d-83202166002e',
  eva: '3fc256c0-b074-4780-b401-20e562827537',
  generiekecontent: '490d3a0c-6e0f-4d12-bacd-a92625ee8973',
  home: '94dcecf2-1725-436c-9205-04f20bcffef0',
  ikmisje: 'd312f523-c7f7-4a46-816b-9d192b376914',
  lazarus: '55177197-688f-4dde-8d1f-509f61babbe7',
  metterdaad: '48c62830-e604-48fa-bc4b-693a8962d21e',
  nlz: '9a669063-a896-4a1e-bc3a-661f02c3f5b7',
  visie: 'c21bea32-65a1-4b4b-a9d8-5f4544b4ff05',
}

// todo make unified
export const constants = {
  preprEnvironments: {
    dit: 'da5bff01-1d38-4f72-b68d-83202166002e',
  },
  npo: {
    audioCdn: 'https://entry.cdn.npoaudio.nl/handle/{id}.mp3',
  },
  sitemapQueries: {
    pagesArticles: {
      domain: 'dit-eo',
    },
    programsPodcasts: {
      communityId: '0b1ff6f8-ee1a-401f-9bf1-49e8dffcac14',
      domain: 'generieke-content-eo',
    },
  } as Record<string, Record<string, string>>,
  placeholders: {
    dit: 'https://eo.cdn.eo.nl/{format}/196kqo3xu26e-placeholder-dit.webp',
  } as Record<string, string>,
  programs: {
    'dit-is-tijs': {
      broadcastIcon: 'npo-1',
      preprId: '24998e9b-d127-4b96-a710-649bec653167',
      preprPodcastId: undefined,
      slug: 'dit-is-tijs',
      thumbnail:
        'https://generieke-content-eo.cdn.eo.nl/{format}/3ty27udv6fmf-dit-is-tijs-foto-willem-jan-de-bruin-fotografie-w63-3748-web.webp',
      title: 'Dit is Tijs',
      pomsId: 'VPWON_1360087',
    },
    'dit-is-de-dag': {
      broadcastIcon: 'npo-radio-1',
      preprId: 'e6659d35-e00a-4799-8f17-9a0a94e71008',
      preprPodcastId: '946a67d5-f74c-4660-b159-d63ee6b74e72',
      slug: 'dit-is-de-dag',
      thumbnail: 'https://eo.cdn.eo.nl/{format}/150ozpui87t2-placeholder-dit-is-de-dag.webp',
      title: 'Dit is de Dag',
      pomsId: 'RBX_S_EO_553956',
    },
    'geld-of-je-leven': {
      broadcastIcon: 'npo-radio-1',
      preprId: '66a07fd8-80f4-48a2-89f5-acb60d6c28be',
      preprPodcastId: '92ebddbc-e182-4c46-aa96-082a347217fb',
      slug: 'geld-of-je-leven',
      thumbnail:
        'https://eo.cdn.eo.nl/{format}/1gwt0epipu1m-placeholder-dit-is-geld-of-je-leven.webp',
      title: 'Dit is geld of je leven',
      pomsId: 'PREPR_S_EO_16715495',
    },
    'de-spindoctors': {
      broadcastIcon: 'npo-radio-1',
      preprId: undefined,
      preprPodcastId: '6a577905-e0ca-4ee0-bd2f-c9a4b8f957e9',
      slug: 'de-spindoctors',
      thumbnail:
        'https://generieke-content-eo.cdn.eo.nl/{format}/07c4c8b2-bb63-441c-bc3d-3edecf80e608.webp',
      title: 'Dit zijn de Spindoctors',
      pomsId: undefined,
    },
    'dit-is-de-kwestie': {
      broadcastIcon: 'npo-2',
      preprId: '131e475f-bc52-44c1-b1d0-95438f2c991f',
      preprPodcastId: undefined,
      slug: 'dit-is-de-kwestie',
      thumbnail: 'https://eo.cdn.eo.nl/{format}/140czv8z7dl2-placeholder-dit-is-de-kwestie.webp',
      title: 'Dit is de Kwestie',
      pomsId: 'VPWON_1339600',
    },
  },
  modelIds: {
    article: '9ad0aecf-f7f9-431c-9193-52bc5bc22971',
    page: 'eceb6d16-a6e8-439c-88d8-286c97a580ff',
    program: 'fa9af868-5af3-49db-8b55-ff1155b2a53b',
  },
  newsletterCampaignIds: {
    dit: '7017U0000009AXiQAM',
  },
  subjectOrder: [
    'Samenleving',
    'Geloof',
    'Geld & werk',
    'Zorg',
    'Veiligheid',
    'Gezin',
    'Politiek',
    'Media & entertainment',
    'Columns',
  ] as string[],
  socials: {
    whatsapp: {
      href: 'https://api.whatsapp.com/send/?phone=31645923535&text=DIT%20AAN',
      label: 'Whatsapp-panel',
    },
    x: {
      label: 'X',
      href: 'https://www.twitter.com/dit_eo/',
    },
    facebook: {
      label: 'Facebook',
      href: 'https://www.facebook.com/dit.eo/',
    },
    instagram: {
      label: 'Instagram',
      href: 'https://www.instagram.com/dit.eo/',
    },
    tiktok: {
      label: 'TikTok',
      href: 'https://www.tiktok.com/@dit.eo',
    },
  },
} as const
