'use client'

import type { ComponentProps } from 'react'

import Link from 'next/link'
import { usePathname } from 'next/navigation'
import {
  Button,
  HeaderAccountMenu,
  Header as HeaderBar,
  HeaderBrands,
  HeaderMenu,
  HeaderMenuTopbar,
  HeaderNpoIcon,
  HeaderRight,
  HeaderTab,
  HeaderTabs,
  Icon,
  iconBlocks,
  iconMonitorPlay,
  iconNews,
  iconSearch,
  Logo,
} from '@eo/next-ui'

import HeaderMenuContent from './HeaderMenuContent'

export default function Header() {
  const pathname = usePathname()

  const tabs: ComponentProps<typeof HeaderTab>[] = [
    {
      ariaLabel: 'Lezen',
      href: '/',
      label: 'Lezen',
      icon: iconNews,
    },
    {
      ariaLabel: 'Kijk & luister',
      label: 'Kijk & luister',
      href: '/kijk-en-luister',
      icon: iconMonitorPlay,
    },
    {
      ariaLabel: 'Doen',
      label: 'Doen',
      href: '/doen',
      icon: iconBlocks,
    },
  ]

  const isTabActive = (href: string) => {
    if (href === pathname) {
      return true
    }
    if (href === '/kijk-en-luister' && pathname.startsWith('/kijk-en-luister')) {
      return true
    }
    if (href === '/doen' && pathname.startsWith('/doen')) {
      return true
    }
    return false
  }
  return (
    <HeaderBar
      colorFrom="white"
      colorTo="white"
      layoutSwitch="md"
      collapsing
    >
      <HeaderMenu
        colorFrom="blue-50"
        colorTo="blue-50"
        buttonProps={{ color: 'blue-50', textColor: 'purple-700' }}
      >
        <HeaderMenuTopbar>
          <HeaderAccountMenu
            brand="dit"
            brandSize={40}
            buttonProps={{ textColor: 'purple-700', className: 'max-sm:order-1' }}
          />
          <Link
            href="https://www.eo.nl/"
            className="max-sm:hidden"
            aria-label="Ga naar de EO."
          >
            <Logo size={24} />
          </Link>
          <HeaderNpoIcon
            variant="npo"
            className="max-sm:sr-only"
          />
        </HeaderMenuTopbar>
        <HeaderMenuContent />
      </HeaderMenu>
      <HeaderBrands className="-my-2 max-sm:scale-75">
        <Link
          href="/"
          aria-label="Ga naar de homepage."
        >
          <Logo
            logo="dit"
            size={48}
          />
        </Link>
        <div className="-order-1 h-10 w-0.5 rounded-full bg-gray-100 sm:hidden" />
        <Link
          href="https://www.eo.nl/"
          className="-order-2 rounded-full sm:hidden"
          aria-label="Ga naar de EO."
        >
          <Logo size={28} />
        </Link>
      </HeaderBrands>
      <HeaderTabs layoutSwitch="md">
        {tabs.map((tab) => (
          <HeaderTab
            key={tab.href}
            active={isTabActive(tab.href)}
            textColor={isTabActive(tab.href) ? 'purple-700' : 'gray-600'}
            color={isTabActive(tab.href) ? 'indigo-50' : 'gray-300'}
            className="lg:mr-5"
            icon={tab.icon}
            {...tab}
          />
        ))}
      </HeaderTabs>
      <HeaderRight>
        <Button
          className="max-lg:hidden"
          outlined
          bgOpacity={0}
          label="Zoek een artikel"
          size="md"
          rounded
          textColor="purple-700"
          to="/zoeken"
        >
          <Icon
            icon={iconSearch}
            size={20}
          />
        </Button>
        <HeaderAccountMenu
          brand="dit"
          brandSize={40}
          buttonProps={{ textColor: 'purple-700', className: 'max-sm:order-1' }}
        />
        <Link
          href="https://www.eo.nl/"
          className="max-sm:hidden"
          aria-label="Ga naar de EO."
        >
          <Logo size={24} />
        </Link>
        <HeaderNpoIcon
          variant="npo"
          className="max-sm:sr-only"
        />
      </HeaderRight>
    </HeaderBar>
  )
}
