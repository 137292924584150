'use client'

import type { ReactNode } from 'react'

import { useEffect } from 'react'
import { usePathname } from 'next/navigation'
import Script from 'next/script'
import { CcmCategories, CookieConsentWrapper } from '@eo/next-ccm'

/**
 * Normally we prevent API TOKENS/IDS in the codebase,
 * and inject them in our pipeline usin the `.env` file
 *
 * These tokens are no secret (window._ain.id and window.tentacles.apiToken)
 * We only have production codes, and only load the scripts in the production environment
 *
 * In the current form we don't know how long the Octo scripts will run at the DiT site,
 * so removal without residual left-overs has now preference.
 */
const API_TOKEN = '6ebimgs683mrz0alu43mjmcdjxxpnd6z'
const DOMAIN_ID = '2937'

export function TentaclesScript(): ReactNode {
  const pathname = usePathname()
  useEffect(() => {
    window.postMessage('activateTentacles')

    /**
     * To test this in the browser:
     *
     * window.addEventListener("message", (event) => {
     *  console.log(event)
     * });
     */
  }, [pathname])

  if (typeof window === 'undefined') return null

  window.tentacles = { apiToken: API_TOKEN }

  window._ain ??= {
    id: DOMAIN_ID,
    page_type: '',
    postid: '',
    maincontent: '',
    title: '',
    pubdate: '',
    authors: '',
    sections: '',
    tags: '',
    access_level: '',
    article_type: '',
    reader_type: 'anonymous',
    trackauto: false,
  }

  const isProd = process.env.NEXT_PUBLIC_ENV === 'production'
  // Setup fake analytics outside of prod for testing purposes
  if (!isProd) {
    let lastSentId = ''
    window._ain.track = () => {
      if (window._ain?.postid === lastSentId) return
      // eslint-disable-next-line no-console
      console.info('SmartOcto (when in production) would send', window._ain)
      lastSentId = window._ain?.postid ?? ''
    }
  }
  return isProd ? (
    <CookieConsentWrapper
      categories={[CcmCategories.ANALYTICS]}
      placeholder={<div className="hidden" />}
    >
      <>
        <Script src="https://d7d3cf2e81d293050033-3dfc0615b0fd7b49143049256703bfce.ssl.cf1.rackcdn.com/stf.js" />
        <Script src="https://tentacles.smartocto.com/ten/tentacle.js" />
      </>
    </CookieConsentWrapper>
  ) : null
}
